const LOCALE_CONFIG_KEY = 'netvision:localeConfig';

const parseAndValidateLocaleConfig = (localeConfig) => {
  if (!localeConfig) return false;

  try {
    const parsedConfig = JSON.parse(localeConfig);

    if (typeof parsedConfig === 'object' && ['name', 'intlName', 'label'].every((key) => key in parsedConfig)) {
      return parsedConfig
    }

    return null
  } catch (error) {
    return null
  }
}

const getSavedLocaleValues = (localeConfigKey) => {
  const config = window.localStorage.getItem(localeConfigKey)
  return parseAndValidateLocaleConfig(config)
};

const saveLocale = (localeConfig) => window.localStorage.setItem(LOCALE_CONFIG_KEY, JSON.stringify(localeConfig));

export function addLocales(siteData) {
  const localeEl = window.document.createElement('meta');
  localeEl.id = 'locale';

  document.head.appendChild(localeEl);

  const locales = siteData.locales?.length
    ? siteData.locales
    : [
        {name: 'ru', intlName: 'ru-RU', label: 'Русский'},
        {name: 'en', intlName: 'en-US', label: 'English'},
      ];

  const emitUpdateLocaleEvent = (localeConfig) => localeEl.dispatchEvent(new CustomEvent('update', { detail: localeConfig }));
  window.APP_LOCALES = locales

  localeEl.setLocale = (newLocale) => {
    if (!locales?.length || !Object.values(locales).every((v) => !!v)) return;
    const locale = locales.find((l) => l.name === newLocale) || locales[0];
    localeEl.setAttribute('locale-name', locale.name);
    localeEl.setAttribute('locale-intlName', locale.intlName);
    saveLocale(locale);
    emitUpdateLocaleEvent(locale);
  };

  window.addEventListener('storage', () => {
    localeEl.setLocale(getSavedLocaleValues(LOCALE_CONFIG_KEY)?.name || locales[0].name);
  });

  localeEl.setLocale(getSavedLocaleValues(LOCALE_CONFIG_KEY)?.name || locales[0].name);
}
